import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { environment } from '../environments/environment';
import { LocalStorageService } from './services/local-storage.service';
import { DataService } from './services/data.service';
import { SeoService } from './services/seo.service';
import { filter, map, mergeMap } from 'rxjs/operators';
import { SharedService } from './services/shared.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    title = 'jodi-angular-cli';
    oneTimeCalled = true;
    analticsData;
    showSpinner = true;
    constructor(private router: Router, private localdata: LocalStorageService, private dataservive: DataService, private seoservice: SeoService, private activatedroutes: ActivatedRoute,private sharedservice: SharedService) {

        if(window.location.pathname === '/user/settings/profile-settings' || window.location.pathname === '/user/settings/profile'){
            this.router.navigate(['/user/my-profile']);
        }

        if(window.location.pathname === '/user/notification/interested-in-you'){
            this.router.navigate(['/user/interested-in-you']);
        }



        router.events.subscribe((val) => {
            const routeValue = val instanceof NavigationEnd;
            // console.log(window);
            if (routeValue) {
                if (environment.production) {
                    console.log('Navigate success in prod');
                    try {
                        const localData = this.localdata.getFromLocal('user');
                        setTimeout(() => {
                            // google
                            // tslint:disable-next-line: prefer-const
                            const windowTemp: any = window;
                            windowTemp.gtag('config', 'G-MWBQRZC0XD', { page_path: window.location.pathname });
                            // fb
                            windowTemp.fbq('init', '266504682395925', {
                                em: localData && localData.email ? localData.email : null
                            });
                            windowTemp.fbq('track', 'PageView');
                            const url = window.location.pathname.split('/')[2];
                            if (url === 'user-account-created') {
                                windowTemp.fbq('track', 'Lead', {
                                    value: 0.50,
                                    currency: 'USD'
                                });
                            } else if (url === 'profile-screening') {
                                windowTemp.fbq('track', 'CompleteRegistration', {
                                    value: 1.00,
                                    currency: 'USD'
                                });
                            }
                            if (localData) {
                                try {
                                    windowTemp.__ls('identify', {
                                        name: localData.firstname ? localData.firstname : null,
                                        email: localData.email ? localData.email : null
                                    });
                                } catch (err) {
                                    // console.log(err);
                                }
                            }

                            // help crunch
                            if (localData && windowTemp.HelpCrunch) {
                                // tslint:disable-next-line: prefer-const
                                const custom = {
                                    email: localData.email ? localData.email : null,
                                    name: localData.firstname ? localData.firstname : null,
                                    PhoneNumber: localData.countrycode ? localData.countrycode : null + ' - ' + localData.phoneNumber ? localData.phoneNumber : null,
                                    custom_data: {
                                        Accountage: localData.accountage ? localData.accountage : null,
                                        isphoneverified: localData.isphoneverified ? localData.isphoneverified : null,
                                        lasturl: window.location.pathname
                                    }
                                };
                                windowTemp.HelpCrunch('updateUser', custom);
                            }

                            // if (this.oneTimeCalled) {
                            //   this.dataservive.getPathRoutes().subscribe(data => {
                            //     if (data) {
                            //       this.analticsData = data;
                            //       this.oneTimeCalled = false;
                            //       this.analyticsFuntion();
                            //     }
                            //   });
                            // } else {
                            //   this.analyticsFuntion();
                            // }
                            // tslint:disable-next-line: variable-name
                            windowTemp.clicky_custom = windowTemp.clicky_custom || {};
                            if (localData && localData.email) {
                                windowTemp.clicky_custom.visitor = {
                                    username: localData.email ? localData.email : null,
                                    email: localData.email ? localData.email : null
                                };
                            }
                        }, 1000);
                    } catch {
                        console.log('error in analytics');
                    }
                } else {
                    // if (this.oneTimeCalled) {
                    //   this.dataservive.getPathRoutes().subscribe(data => {
                    //     if (data) {
                    //       this.analticsData = data;
                    //       this.oneTimeCalled = false;
                    //       this.analyticsFuntion();
                    //     }
                    //   });
                    // } else {
                    //   this.analyticsFuntion();
                    // }
                }
            }
        });
    }

    ngOnInit(): void {

        // here we check if user click through outside link its proper navigate.
        const localStoreURL = {
            Url : (window.location.pathname + window.location.search).substr(0),
            isStatic : true
        }
        if(localStoreURL.Url.indexOf("/user") > -1 ||  localStoreURL.Url.indexOf("/register") > -1){
            localStoreURL.isStatic = false;
        }
        if(window.location.pathname === '/user/settings/profile-settings' || window.location.pathname === '/user/settings/profile'){
            localStoreURL.Url = '/user/my-profile';
        }
        if(window.location.pathname === '/user/notification/interested-in-you'){
            localStoreURL.Url = '/user/interested-in-you';
        }
        this.localdata.setToLocal('RequestUrl', localStoreURL);

        // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        // Add 'implements OnInit' to the class.
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.activatedroutes),
            map((route) => {
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            }),
            filter((route) => route.outlet === 'primary'),
            mergeMap((route) => route.data)
        )
            .subscribe((event) => {
                this.showSpinner = false;
                this.seoservice.setTitle(event.title);
                // this.seoservice.setMetaUrl(event.url);
                this.seoservice.setMetaDescription(event.description);
                this.seoservice.setMetaImage(event.image);


                if (event instanceof NavigationStart) {
                    this.showSpinner = true;
                }

                if (event instanceof NavigationEnd) {
                    this.showSpinner = false;
                }
            });

        this.dataservive.getAmplitudeData().subscribe((result : any)=> {
            this.sharedservice.amplitudeData = result;
        });
        this.dataservive.getFbEventData().subscribe((result)=>{
            this.sharedservice.customFbEventsData = result;
        });
        // this.router.events.pipe(
        //   filter((event) => event instanceof NavigationEnd))
        //   .subscribe((event) => {
        //     console.log('NavigationEnd:', event);
        //   });

        // Helpcrunch Script Call 
        const loadThirdPartyScript = () => {
            const script = document.createElement('script');
            script.src = 'assets/custom-helpcrunch.js';
            script.defer = true;
            document.body.appendChild(script);

            // Remove event listeners after script loads
            //document.removeEventListener('mousemove', loadThirdPartyScript);
            document.removeEventListener('touchstart', loadThirdPartyScript);
        };

        // Listen for user interaction (mousemove or touch)
        // document.addEventListener('mousemove', loadThirdPartyScript, { once: true });
        document.addEventListener('touchstart', loadThirdPartyScript, { once: true });
    }
    analyticsFuntion() {
        const windowTemp: any = window;
        const eventProperties = {
            url: window.location.href
        };
        console.log(window.location.pathname);
    
        const eventname = this.analticsData ? this.analticsData.postlogin[window.location.pathname] : null;
        const eventname1 = this.analticsData ? this.analticsData.prelogin[window.location.pathname] : null;
        const eventname2 = this.analticsData ? this.analticsData.createprofile[window.location.pathname] : null;
        if (eventname || eventname1 || eventname2) {
            if (window.location.pathname.indexOf('logout') > -1) {
                windowTemp.amplitude.getInstance().setUserId(null);
            } else {
                windowTemp.amplitude.logEvent(eventname || eventname1 || eventname2, eventProperties);
            }
            // windowTemp.clevertap.event.push(eventname || eventname1 || eventname2);
        }
    }
}
