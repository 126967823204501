import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    constructor() { }

    // setting to local (GLOBAL FUNCTION)
    setToLocal(key: string, value) {
        try {
            localStorage.setItem(key, JSON.stringify(value));
        } catch (err) {
            console.log('error is setting in local', err);
        }
    }

    // getting from local (GLOBAL FUNCTION)

    getFromLocal(key: string) {
        try {
            return JSON.parse(localStorage.getItem(key));
        } catch (err) {
            console.log('error is gettin from local', err);
        }
    }

    // removing token
    removeToken(key: string) {
        try {
            localStorage.removeItem(key);
        } catch (err) {
            console.log('error is removing from local', err);
        }
    }
}
