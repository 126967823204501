import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class SeoService {

    constructor(private title: Title, private meta: Meta) { }

    setTitle(value) {
    // console.log(value);
        this.title.setTitle(value);
        this.meta.updateTag({ itemprop: 'name', content: value }, 'itemprop="name"');
        this.meta.updateTag({ property: 'og:title', content: value }, 'property="og.title"');
        this.meta.updateTag({ name: 'twitter:title', content: value }, 'name="twitter.title"');
    }
    setMetaDescription(value) {
    // console.log(value);
        this.meta.updateTag({ itemprop: 'description', content: value }, 'itemprop="description"');
        this.meta.updateTag({ property: 'og:description', content: value }, 'property="og.description"');
        this.meta.updateTag({ name: 'twitter:description', content: value }, 'name="twitter.description"');
    }
    setMetaImage(value) {
        this.meta.updateTag({ itemprop: 'image', content: value }, 'itemprop="image"');
        this.meta.updateTag({ property: 'og:image', content: value }, 'property="og.image"');
        this.meta.updateTag({ name: 'twitter:image', content: value }, 'name="twitter.image"');
    }
    setMetaUrl(value) {
        this.meta.updateTag({ name: 'url', content: value });
        this.meta.updateTag({ property: 'og:url', content: value });
        this.meta.updateTag({ name: 'twitter:url', content: value });
    }
}
