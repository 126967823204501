import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class DataService {

    constructor(private http: HttpClient) { }

    getUrl(filename) {
        return '../../assets/json/' + filename;
    }

    getAllCountries() {
        return this.http.get(this.getUrl('countries.json'));
    }

    getAllCountriesCodes() {
        return this.http.get(this.getUrl('country-code.json'));
    }

    getAllCompanies() {
        return this.http.get(this.getUrl('companies.json'));
    }

    getPreferencesData(): any {
        return this.http.get(this.getUrl('preferences.json'));
    }

    getQuestions() {
        return this.http.get(this.getUrl('questions.json'));
    }

    getPathRoutes() {
        return this.http.get(this.getUrl('path-routes-for-analytics.json'));
    }

    getAllUserReviews() {
        return this.http.get(this.getUrl('user-reviews.json'));
    }
    getReligionContent() {
        return this.http.get(this.getUrl('religion.json'));
    }
    getAmplitudeData(){
        return this.http.get(this.getUrl('amplitude.json'));
    }
    getFbEventData(){
        return this.http.get(this.getUrl('fb-events.json'));
    }
}
