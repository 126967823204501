import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiService } from './services/api.service';
import { DataService } from './services/data.service';
import { AuthService, CreateProfileService, StaticModuleService, ProfileScreeningGuard, PostLoginGuardForProfileScreening } from './services/auth.service';
import { LocalStorageService } from './services/local-storage.service';
import { SharedService } from './services/shared.service';
import { SeoService } from './services/seo.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatSnackBarModule,
        FontAwesomeModule
    ],
    providers: [
        ApiService,
        DataService,
        AuthService,
        CreateProfileService,
        LocalStorageService,
        StaticModuleService,
        SharedService,
        SeoService,
        ProfileScreeningGuard,
        PostLoginGuardForProfileScreening,
        {provide: HTTP_INTERCEPTORS, multi: true, useClass: ApiService}
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
